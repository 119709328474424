<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <analytics-congratulation :data="user" />
      </b-col>
      <b-col
        v-for="(data, index) in associados"
        :key="index"
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="UsersIcon"
          :statistic="kFormatter(data.tipo.total)"
          :statistic-title="data.tipo.series[0].name"
          :chart-data="data.tipo.series"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <ecommerce-statistics :data="tipoRegistros" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'

export default {
  computed: {
    ...mapState('associantesAdministrativeModule', {
      comboTypesRegister: state => state.comboTypesRegister,
    }),
    ...mapState('aggregatesAdministrativeModule', {
      aggregate: state => state.aggregate,
    }),
  },
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    StatisticCardWithAreaChart,
    EcommerceStatistics,
  },
  data() {
    return {
      user: {},
      associados: [],
      data: {},
      tipoRegistros: [],
    }
  },
  mounted() {
    this.getDadosUser()
    this.getNumeroAssociadosAtivosInativos()
    this.getContagemTipoSRegistros()
  },
  methods: {
    kFormatter,
    getDadosUser() {
      this.user = JSON.parse(localStorage.getItem('userData'))
    },
    getNumeroAssociadosAtivosInativos() {
      this.$store.dispatch('associantesAdministrativeModule/loadCards').then(response => {
        response.data.forEach(element => {
          this.associados.push({
            tipo: {
              total: element.valor,
              series: [
                {
                  data: [0, element.valor],
                  name: element.descricao,
                },
              ],
            },
          })
        })
      })
    },
    getContagemTipoSRegistros() {
      this.tipoRegistros = []
      this.$store.dispatch('associantesAdministrativeModule/getContagemTiposRegistros').then(response => {
        response.data.forEach(tipo => {
          const obj = {
            color: 'light-primary',
            customClass: 'mb-3 mb-xl-2',
            icon: 'TrendingUpIcon',
            subtitle: tipo.descricao,
            title: tipo.valor,
          }
          this.tipoRegistros.push(obj)
        })
      })
    },
  },
}
</script>
